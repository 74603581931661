import { CartIcon, OutOfStockIcon } from "../components/SVGs.tsx";
import { showFile } from "../utils/file.ts";

function ProductCard({ product, addToCart }: any) {
  const stock = product?.countInStock;
  const p = product.variants[0];
  return (
    <div className="col-lg-3 p15">
      <div className="product-card">
        <div>
          <img
            className="pcard-img"
            src={`${showFile(product.image)}`}
            alt=""
          />
        </div>
        <div className="pcard-content">
          {/* <p className="pcard-cat">Category</p> */}
          <h6 className="font-medium text-sm mb-1">{product.name}</h6>
          {/* <h6 className="font-medium text-sm mb-1">{product.name}&nbsp;{p.quantity}&nbsp;{p.unit}</h6> */}
          {/* <p className="pcard-cat">Tablet&nbsp;&nbsp;•&nbsp;&nbsp;{p.quantity}</p> */}
          <p className="pcard-price">{p.price}kr</p>
          <div className="pcard-cart">
            {stock > 0
              ? (
                <button
                  class="bg-[#3f51b5] text-white"
                  onClick={() => addToCart(product)}
                >
                  <i className="fas fa-cart-plus">
                    <CartIcon />
                  </i>&nbsp;Köp
                </button>
              )
              : (
                <button className="bg-[#596acb] text-gray-100 !cursor-not-allowed">
                  <i className="fas fa-cart-plus">
                    <OutOfStockIcon />
                  </i>&nbsp;Slut i lager
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;

